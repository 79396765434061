<template>
  <!-- Begin page -->
  <div id="layout-wrapper">
    <Topbar />
    <Sidebar
      :is-condensed="isMenuCondensed"
      :type="leftSidebarType"
      :width="layoutWidth"
      :routeName="routeName"
      v-if="!userIsCrupier"
    />
    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div
      class="main-content min-vh-100 d-flex flex-column justify-content-between"
    >
      <div class="page-content">
        <div class="container-fluid">
          <b-overlay :show="overlayLoader" rounded="sm" z-index="1000">
            <slot />
          </b-overlay>
        </div>
      </div>
      <!-- End Page-content -->
      <Footer v-if="!userIsCrupier" />
    </div>
    <!-- end main content-->
  </div>
</template>

<script>
import router from '@/router';
import { layoutComputed } from '@/state/stateHelpers';
import Topbar from '@/components/topbar';
import Sidebar from '@/components/side-bar';
import Footer from '@/components/footer';
import { mapMutations, mapGetters } from 'vuex';

export default {
  data() {
    return {
      isMenuCondensed: false,
      routeName: 'Inicio',
      render: false,
      role: '',
    };
  },
  components: {
    Topbar,
    Sidebar,
    Footer,
  },
  computed: {
    ...layoutComputed,
    ...mapGetters({
      overlayLoader: 'user/getOverlayLoader',
      userIsCrupier: 'user/isCrupier',
    }),
  },
  name: 'vertical',
  created() {
    document.body.removeAttribute('data-layout');
    document.body.removeAttribute('data-topbar');
  },
  methods: {
    getUserRole() {
      const userData = this.$store.getters['user/getUserData'];
      const lsUser = JSON.parse(localStorage.getItem('user'));
      const rol = userData
        ? userData.rol
          ? userData.rol
          : lsUser.user.rol
        : lsUser.user.rol;
      this.role = rol;
      this.setRole(this.role);
      this.setUser(lsUser.user);
    },
    toggleRightSidebar() {
      document.body.classList.toggle('right-bar-enabled');
    },
    hideRightSidebar() {
      document.body.classList.remove('right-bar-enabled');
    },
    toggleMenu() {
      document.body.classList.toggle('sidebar-enable');

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove('sidebar-enable');
          document.body.classList.remove('vertical-collpsed');
        });
        document.body.classList.toggle('vertical-collpsed');
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove('sidebar-enable');
        });
        document.body.classList.remove('vertical-collpsed');
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    ...mapMutations({
      setRole: 'user/setRole',
      setUser: 'user/setUser',
    }),
  },
  updated() {
    this.routeName = this.$route.path;
  },
  mounted() {
    this.getUserRole();
  },
};
</script>
