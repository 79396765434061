var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hidden"},[_c('vs-sidebar',{attrs:{"hover-expand":_vm.isHoverExpand,"reduce":_vm.isSideBarReduce,"open":_vm.isOpenSideBar},scopedSlots:_vm._u([{key:"logo",fn:function(){return [_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/images/SPRINT_GAMING_PNG-.png")}})])]},proxy:true}]),model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}},_vm._l((_vm.getMenu(_vm.savedRole)),function(item,index){return _c('div',{key:index},[(item.required)?[(!item.isTitle && !item.subItems && !item.required.beAdmin)?_c('vs-sidebar-item',{attrs:{"id":item.link},scopedSlots:_vm._u([(item.icon)?{key:"icon",fn:function(){return [_c('router-link',{attrs:{"to":item.link}},[_c('i',{class:item.icon})])]},proxy:true}:null],null,true)},[_c('router-link',{attrs:{"to":item.link}},[_vm._v(" "+_vm._s(item.label)+" ")])],1):_vm._e(),(item.subItems && !item.required.notShow)?_c('vs-sidebar-group',{scopedSlots:_vm._u([{key:"header",fn:function(){return [(!item.required.beAdmin)?_c('vs-sidebar-item',{attrs:{"arrow":""},scopedSlots:_vm._u([(item.icon)?{key:"icon",fn:function(){return [_c('i',{class:item.icon})]},proxy:true}:null],null,true)},[_vm._v(" "+_vm._s(item.label)+" ")]):_vm._e()]},proxy:true}],null,true)},_vm._l((item.subItems),function(subI,index){return _c('vs-sidebar-item',{key:index,attrs:{"id":subI.link},scopedSlots:_vm._u([(subI.icon)?{key:"icon",fn:function(){return [(subI.required)?[(!subI.required.beAdmin)?[_c('router-link',{attrs:{"to":subI.link}},[_c('i',{class:subI.icon,style:(`display: ${
                        !subI.required.beAdmin ? 'block' : 'none'
                      }`)})])]:_vm._e()]:_vm._e()]},proxy:true}:null],null,true)},[(subI.required)?[(!subI.required.beAdmin)?[_c('router-link',{style:(`display: ${
                    !subI.required.beAdmin ? 'block' : 'none'
                  }`),attrs:{"to":subI.link}},[_vm._v(" "+_vm._s(subI.label)+" ")])]:_vm._e()]:_vm._e()],2)}),1):_vm._e()]:[(!item.isTitle && !item.subItems)?_c('vs-sidebar-item',{attrs:{"id":item.link},scopedSlots:_vm._u([(item.icon)?{key:"icon",fn:function(){return [_c('router-link',{attrs:{"to":item.link}},[_c('i',{class:item.icon})])]},proxy:true}:null],null,true)},[_c('router-link',{attrs:{"to":item.link}},[_vm._v(" "+_vm._s(item.label)+" ")])],1):_vm._e(),(item.subItems)?_c('vs-sidebar-group',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('vs-sidebar-item',{attrs:{"arrow":""},scopedSlots:_vm._u([(item.icon)?{key:"icon",fn:function(){return [_c('i',{class:item.icon})]},proxy:true}:null],null,true)},[_vm._v(" "+_vm._s(item.label)+" ")])]},proxy:true}],null,true)},_vm._l((item.subItems),function(subI,index){return _c('vs-sidebar-item',{key:index,style:(`display: ${subI.required.beAdmin ? 'flex' : 'none'}`),attrs:{"id":subI.link},scopedSlots:_vm._u([(subI.icon && subI.required.beAdmin)?{key:"icon",fn:function(){return [_c('router-link',{attrs:{"to":subI.link}},[_c('i',{class:subI.icon,style:(`display: ${
                    subI.required.beAdmin ? 'block' : 'none'
                  }`)})])]},proxy:true}:null],null,true)},[_c('router-link',{style:(`display: ${subI.required.beAdmin ? 'block' : 'none'}`),attrs:{"to":subI.link}},[_vm._v(" "+_vm._s(subI.label)+" ")])],1)}),1):_vm._e()]],2)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }