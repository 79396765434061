import i18n from '@/i18n';
import { ADMIN_ROLE, CLIENT_ROLE, OPERATOR_ROLE } from '@/helpers/CONSTANTS';

let user = JSON.parse(localStorage.getItem('user'));
const role = user.user.role.name;

const roles = [
  ADMIN_ROLE,
  'ADMIN_OPERATOR',
  CLIENT_ROLE,
  OPERATOR_ROLE,
  'PARTNER',
];
const roleViewAll = [ADMIN_ROLE, 'ADMIN_OPERATOR', 'PARTNER'];

export const menuItems = [
  {
    id: 1,
    label: 'Menu',
    isTitle: true,
  },
  {
    id: 2,
    label: i18n.t('sidebar.home'),
    icon: 'uil-home-alt',
    link: '/',
  },

  {
    id: 4,
    label: i18n.t('sidebar.currency'),
    icon: 'bx bx-money',
    link: '/currencies',

    required: {
      // beAdmin: true,
      beAdmin: !roleViewAll.includes(role),
    },
  },
  {
    id: 5,
    label: i18n.t('sidebar.clients'),
    icon: 'mdi mdi-account-box-multiple',
    link: '/clients',
    required: {
      beAdmin: !roleViewAll.includes(role),
    },
  },
  {
    id: 6,
    label: i18n.t('sidebar.operators'),
    icon: 'mdi mdi-desktop-classic',
    link: '/operators',
    required: {
      beAdmin: role === 'OPERATOR',
    },
  },
  {
    id: 7,
    label: i18n.t('sidebar.players'),
    icon: 'mdi mdi-nintendo-game-boy',
    link: '/players',
    required: {
      beAdmin: !roleViewAll.includes(role),
      // beAdmin: roles.includes(role),
    },
  },
  {
    id: 8,
    label: 'Ruletas',
    icon: 'uil-bullseye',
    required: {
      beAdmin: !roleViewAll.includes(role),
      notShow: true,
    },
    subItems: [
      {
        id: 666,
        label: i18n.t('routeMenu.rouletteVirtual'),
        link: '/roulettes',
        parentId: 8,
        icon: 'uil-cube',
        required: {
          beAdmin: !roleViewAll.includes(role),
        },
      },
      {
        id: 69,
        label: i18n.t('routeMenu.rouletteFisic'),
        link: '/roulettes-fisics',
        parentId: 8,
        icon: 'uil-cube',
        required: {
          beAdmin: !roleViewAll.includes(role),
        },
      },
    ],
  },
  {
    id: 99,
    label: 'Tigre Dragón',
    icon: 'uil-bullseye',
    required: {
      beAdmin: !roleViewAll.includes(role),
      notShow: true,
    },
    subItems: [
      {
        id: 991,
        label: 'Tigre Dragón Virtual',
        link: '/tigerDragon',
        parentId: 99,
        icon: 'uil-cube',
        required: {
          beAdmin: !roleViewAll.includes(role),
        },
      },
      {
        id: 992,
        label: 'Tigre Dragón Físico',
        link: '/tigerDragon-fisics',
        parentId: 99,
        icon: 'uil-cube',
        required: {
          beAdmin: !roleViewAll.includes(role),
        },
      },
    ],
  },
  {
    id: 88,
    label: i18n.t('sidebar.games.text'),
    icon: 'uil-game-structure',
    required: {
      beAdmin: !roleViewAll.includes(role),
    },
    subItems: [
      {
        id: 881,
        label: i18n.t('sidebar.games.subItem.roulettes'),
        link: '/roulettes',
        parentId: 88,
        icon: 'uil-cube',
        required: {
          beAdmin: !roleViewAll.includes(role),
        },
      },
      {
        id: 882,
        label: i18n.t('sidebar.games.subItem.tigerDragon'),
        link: '/tigerDragon',
        parentId: 88,
        icon: 'uil-cube',
        required: {
          beAdmin: !roleViewAll.includes(role),
        },
      },
      {
        id: 883,
        label: i18n.t('sidebar.games.subItem.externalGames'),
        link: '/external-games',
        parentId: 88,
        icon: 'uil-cube',
        required: {
          beAdmin: !roleViewAll.includes(role),
        },
      },
      {
        id: 884,
        label: 'Rueda de la suerte',
        link: '/sprint-wheels',
        parentId: 88,
        icon: 'uil-cube',
        required: {
          beAdmin: !roleViewAll.includes(role),
        },
      },
      {
        id: 885,
        label: 'Black Jack',
        link: '/black-jack',
        parentId: 88,
        icon: 'uil-cube',
        required: {
          beAdmin: !roleViewAll.includes(role),
        },
      },
    ],
  },
  {
    id: 9,
    label: i18n.t('sidebar.users'),
    icon: 'uil-users-alt',
    link: '/users',
    required: {
      beAdmin: !roleViewAll.includes(role),
    },
  },
  {
    id: 999,
    label: i18n.t('sidebar.manualPayment.text'),
    icon: 'uil-money-withdrawal',
    required: {
      beAdmin: role !== ADMIN_ROLE,
    },
    subItems: [
      {
        id: 9991,
        label: i18n.t('sidebar.manualPayment.subItem.individual'),
        link: '/manual-payment/individual',
        parentId: 999,
        icon: 'uil-user',
        required: {
          beAdmin: role !== ADMIN_ROLE,
        },
      },
      {
        id: 9992,
        label: i18n.t('sidebar.manualPayment.subItem.general'),
        link: '/manual-payment/general',
        icon: 'uil-user-plus',
        parentId: 999,
        required: {
          beAdmin: role !== ADMIN_ROLE,
        },
      },
    ],
  },
  {
    id: 10,
    label: i18n.t('sidebar.reports.text'),
    icon: 'dripicons-export',
    subItems: [
      {
        id: 9,
        label: i18n.t('sidebar.reports.subItems.transactions'),
        link: '/reports/transations',
        parentId: 10,
        required: {
          beAdmin: roles.includes(role),
        },
        icon: 'uil-transaction',
      },
      {
        id: 91,
        label: 'Jackpots',
        link: '/reports/jackpots',
        parentId: 10,
        required: {
          beAdmin: roles.includes(role),
        },
        icon: 'uil-star',
      },
      {
        id: 10,
        label: i18n.t('sidebar.reports.subItems.historial'),
        link: '/reports/histories',
        parentId: 10,
        required: {
          beAdmin: roles.includes(role),
        },
        icon: 'uil-history',
      },
      {
        id: 11,
        label: i18n.t('sidebar.reports.subItems.players'),
        link: '/reports/players',
        parentId: 10,
        required: {
          beAdmin: roles.includes(role),
        },
        icon: 'uil-users-alt',
      },
      /* {
        id: 12,
        label: "Detalle de Jugadores",
        link: "/reports/detail/players",
        parentId: 8,
      }, */
      {
        id: 13,
        label: i18n.t('sidebar.reports.subItems.ggr'),
        link: '/reports/ggr',
        parentId: 10,
        required: {
          beAdmin: roles.includes(role),
        },
        icon: 'uil-graph-bar',
      },
      {
        id: 132,
        label: i18n.t('sidebar.reports.subItems.ggrByCasino'),
        link: '/reports/ggr-by-casino',
        parentId: 10,
        required: {
          beAdmin: roles.includes(role),
        },
        icon: 'uil-comparison',
      },
      {
        id: 1322,
        label: 'GGR BY WL',
        link: '/reports/ggr-by-wl',
        parentId: 10,
        required: {
          beAdmin: roles.includes(role),
        },
        icon: 'uil-comparison',
      },
      {
        id: 14,
        label: i18n.t('sidebar.reports.subItems.rounds'),
        link: '/reports/rounds',
        parentId: 10,
        required: {
          beAdmin: roles.includes(role),
        },
        icon: 'uil-adjust-half',
      },
      {
        id: 15,
        label: i18n.t('sidebar.reports.subItems.providerResults'),
        link: '/reports/provider-logs',
        parentId: 10,
        required: {
          beAdmin: roleViewAll.includes(role),
        },
        icon: 'uil-newspaper',
      },
      {
        id: 16,
        label: i18n.t('sidebar.reports.subItems.currencyFluctuation'),
        link: '/reports/currencies',
        parentId: 10,
        required: {
          beAdmin: roleViewAll.includes(role),
        },
        icon: 'uil-file-graph',
      },
      {
        id: 17,
        label: i18n.t('sidebar.reports.subItems.stockAudit'),
        link: '/reports/auditory',
        parentId: 10,
        required: {
          beAdmin: roleViewAll.includes(role),
        },
        icon: 'uil-comment-alt-chart-lines',
      },
      {
        id: 188,
        label: i18n.t('betLogs.title'),
        link: '/reports/bet-logs',
        parentId: 10,
        required: {
          beAdmin: roles.includes(role),
        },
        icon: 'uil-comment-alt-chart-lines',
      },
      {
        id: 189,
        label: 'Visitas / Apuestas',
        link: '/reports/views-bets-by-round',
        parentId: 10,
        required: {
          beAdmin: roles.includes(role),
        },
        icon: 'uil-calendar-alt',
      },
      {
        id: 190,
        label: 'Jugadores en linea',
        link: '/reports/players-online',
        parentId: 10,
        required: {
          beAdmin: roles.includes(role),
        },
        icon: 'uil-user-arrows',
      },
    ],
  },
  {
    id: 15,
    label: i18n.t('sidebar.technicalSupport'),
    icon: 'uil-comment-alt-info',
    link: '/technical-support',
  },
  {
    id: 16,
    label: i18n.t('sidebar.exchangeAPIs'),
    icon: 'uil-cloud-computing',
    link: '/exchange-apis',
    required: {
      beAdmin: !roleViewAll.includes(role),
    },
  },
  {
    id: 17,
    label: i18n.t('sidebar.crupiers'),
    icon: 'uil-users-alt',
    link: '/crupiers',
    required: {
      beAdmin: !roleViewAll.includes(role),
    },
  },
  {
    id: 17,
    label: i18n.t('sidebar.help'),
    icon: 'uil-question-circle',
    link: '/help',
  },
  {
    id: 177,
    label: i18n.t('sidebar.roles'),
    icon: 'uil-user',
    link: '/roles',
  },
];
